import React, {useEffect} from 'react'
import Layout from '../components/Layout'
import Projects from '../components/Projects'
import Seo from '../components/Seo'
import HeroSection from '../components/HeroSection'
import loadingSpinner from "../../static/x0UPb88253.json"

const Work = () => {

  return (
    <Layout>
        <Seo title="Work" />
        <HeroSection lottiefile={loadingSpinner} title={'Work'} subtitle={"Thing's I've made or learned at the companies I have worked for."} />
        <Projects />
    </Layout>
  )
}

export default Work
